import api from '@/shared/api/token'

const ENDPOINT = '/settings'

class SettingsService {
  fetch() {
    return api.get(ENDPOINT).then((response) => {
      return response.data.data
    })
  }

  save(data) {
    return this.update(data)
  }

  update(data) {
    return api.put(ENDPOINT, data).then((response) => {
      return response.data
    })
  }
}

export default new SettingsService()
