<template>
  <div class="InvoiceAllocations" v-if="invoice">
    <h3>
      Wallets
      {{ date }}
    </h3>

    <table class="InvoiceAllocations__table InvoiceAllocations__table__main table">
      <colgroup>
        <col style="width:175px">
        <col>
        <col style="width:175px">
      </colgroup>

      <thead>
        <tr>
          <th>Exchange</th>
          <th>Item description</th>
          <th class="text-end">Total</th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="(allocation, index) in invoice.allocations" :key="index">
          <td>
            <ExchangeName :exchange="allocation[1]" />
          </td>

          <td>
            {{ allocation[0] }}
          </td>

          <td class="text-end">
            ${{ formatDecimal(allocation[2]) }}
          </td>
        </tr>
      </tbody>

      <tfoot>
        <tr>
          <td />

          <td>
            Portfolio value
            <time>{{ date }}</time>
          </td>

          <td class="text-end">
            ${{ formatDecimal(sum(invoice.allocations)) }}
          </td>
        </tr>
      </tfoot>
    </table>
  </div>
</template>

<script>
  import ExchangeName from '@/components/ExchangeName'

  import { formatDate } from '@/utils/date'
  import { formatDecimal } from '@/utils/number'

  export default {
    components: {
      ExchangeName
    },

    props: {
      invoice: {
        type: Object,
        default: null
      }
    },

    computed: {
      date() {
        return formatDate(this.invoice.date, 'MMMM do, yyyy')
      },

      serviceDate() {
        return formatDate(this.invoice.period.date, 'MMMM do, yyyy')
      }
    },

    methods: {
      formatDate,
      formatDecimal,

      sum(allocations) {
        return allocations.reduce((total, allocation) => {
          return total + allocation[2]
        }, 0)
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import '@/sass/styles.vars.all.sass'

  .InvoiceAllocations
    break-after: avoid-page
    padding: $spacer $spacer-5

    h3
      font-size: 1.8em

    time
      display: block
      font-weight: normal

  .InvoiceAllocations__table
    margin-bottom: 150px
    vertical-align: middle
    font-size: 1.1em

    thead
      td, th
        background: $primary
        color: white

    tbody
      tr
        height: 4em

      th, td
        font-weight: bold

    tfoot
      th, td
        font-weight: bold
        color: $primary

      td:last-child
        color: $body-color

      th
        color: white
        background: $primary
        text-transform: uppercase
        font-size: 1.2em

  .InvoiceAllocations__table__footer
    margin-top: 1em

  .InvoiceAllocations__table__notes
    border-bottom-color: transparent
    vertical-align: top

  .InvoiceAllocations__notes
    font-weight: normal
    padding-right: $spacer
    font-size: 9px
    color: $body-color

    p:last-child
      margin: 0

  .InvoiceAllocations__table__main
    thead
      th
        background: $tertiary

    tr:nth-child(even)
      background: $gray-100
</style>
