<template>
  <div class="InvoicePayment">
    <div class="InvoicePayment__cryptos">
      <h3>
        Payment by
        <img src="@/assets/logo-tether.svg">
      </h3>

      <p>
        Send USDT/Tether (ERC20 or BSC networks) to the following USDT Address:
        <em>{{ invoice.global_settings.invoice_usdt_address }}</em>
      </p>
    </div>

    <div class="InvoicePayment__banks">
      <h3>Or Follow steps to use bank wire</h3>

      <h4>Bank account</h4>
      <p>IN TRANSFER DETAIL, DO NOT WRITE THE WORDS "CRYPTO OR BTC". SIMPLY PUT THE DATE + PERIOD OF THE INVOICE AND "CONSULTING SERVICES"</p>

      <div class="InvoicePayment__accounts row">
        <div class="col-6">
          <h4>EUR Account</h4>
          <div v-html="invoice.global_settings.invoice_eu_bank_account" />
        </div>

        <div class="col-6">
          <h4>USD Account</h4>
          <div v-html="invoice.global_settings.invoice_usd_bank_account" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      invoice: {
        type: Object,
        default: null
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import '@/sass/styles.vars.all.sass'

  .InvoicePayment
    break-after: avoid-page
    padding: $spacer $spacer-5
    font-size: .9em

    h3
      font-size: 1.4em
      text-transform: uppercase

    h4
      font-size: 1.2em
      color: $gray-700

  .InvoicePayment__cryptos
    h3
      display: flex
      align-items: center

      img
        height: 20px
        margin-left: 10px
        margin-top: -.2em

    em
      font-style: normal
      color: $primary
      font-family: monospace
      display: block
</style>

<style lang="sass">
  .InvoicePayment__accounts b
      margin-right: .5em
</style>
