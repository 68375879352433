<template>
  <div class="InvoiceInfo" v-if="invoice">
    <div class="InvoiceInfo__top-one">
      <div class="row">
        <div class="col-6">
          <div class="InvoiceInfo__top-one__left">
            <time>{{ date }}</time>
          </div>
        </div>

        <div class="col-6">
          <div class="InvoiceInfo__top-one__right">
            <strong>Invoice No </strong> <span>{{ invoice.number }}</span> <br>
            <strong>Account No </strong> <span>{{ invoice.client.number }}</span> <br>
            <strong>Tax ID </strong> <span>{{ invoice.global_settings.invoice_tax_id }}</span> <br>
            <strong>Date </strong> <span>{{ date }}</span> <br>
          </div>
        </div>
      </div>
    </div>

    <div class="InvoiceInfo__top-two">
      <div class="row">
        <div class="col-4">
          <div class="InvoiceInfo__top-two__left">
            <h2>Invoice to</h2>

            <div v-if="invoice.client.company_name">
              <h3>{{ invoice.client.company_name }}</h3>
              <h4>{{ invoice.client.lastname }} {{ invoice.client.firstname }}</h4>
              <p>
                <strong>V:</strong> <span>{{ invoice.client.vat }}</span> <br>
                <strong>P:</strong> <span>{{ invoice.client.phone }}</span> <br>
                <strong>E:</strong> <span>{{ invoice.client.email }}</span> <br>
                <strong>C:</strong> <span>{{ mapCountry(invoice.client.country) }}</span> <br>
              </p>
            </div>

            <div v-if="!invoice.client.company_name">
              <h3>{{ invoice.client.lastname }} {{ invoice.client.firstname }}</h3>
              <p>
                <strong>P:</strong> <span>{{ invoice.client.phone }}</span> <br>
                <strong>E:</strong> <span>{{ invoice.client.email }}</span> <br>
                <strong>C:</strong> <span>{{ mapCountry(invoice.client.country) }}</span> <br>
              </p>
            </div>
          </div>
        </div>

        <div class="col-8">
          <div class="InvoiceInfo__top-two__right">
            <h2>
              Service period
              ({{ serviceDate }})
            </h2>

            <p v-if="invoice.mode === 'hwm'">
              ISPV / Inception Starting Portfolio Value (deposits/withdraws) = <strong>${{ formatDecimal(invoice.statements.inception) }}</strong> <br>
              Previous High Watermark ({{ hwmDate }}): <strong>${{ formatDecimal(invoice.hwm.previous) }}</strong> <br>
              Updated High Watermark (deposits/withdraws): <strong>${{ formatDecimal(invoice.hwm.adjusted) }}</strong> <br>
              Profits (performance {{ serviceDate }})
              = <strong>${{ formatDecimal(invoice.period.performance) }}</strong> (generated profits) = <strong>${{ formatDecimal(invoice.statements.current) }} - ${{ formatDecimal(invoice.hwm.adjusted) }}</strong> <br>
              New High Watermark Value:
              ${{ formatDecimal(invoice.hwm.current) }} - ${{ formatDecimal(invoice.due.performance_fee + invoice.due.quaterly_fee) }} = <strong>${{ formatDecimal(invoice.hwm.new) }}</strong> <br>
            </p>

            <p v-else>
              ISPV / Inception Starting Portfolio Value (deposits/withdraws) = <strong>${{ formatDecimal(invoice.statements.inception) }}</strong> <br>
              Last Statement, Portfolio {{ serviceDate }} = <strong>${{ formatDecimal(invoice.statements.previous) }}</strong> <br>
              Profits (performance {{ serviceDate }})
              = ${{ formatDecimal(invoice.period.performance) }} (generated profits) = <strong>${{ formatDecimal(invoice.statements.current) }} - ${{ formatDecimal(invoice.statements.adjusted) }}</strong> <br>
              New Monthly statement, Portfolio Value {{ date }}:
              ${{ formatDecimal(invoice.statements.current) }} - ${{ formatDecimal(invoice.due.performance_fee + invoice.due.quaterly_fee) }} = <strong>${{ formatDecimal(invoice.statements.new) }}</strong> <br>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { formatDate } from '@/utils/date'
  import { formatDecimal } from '@/utils/number'
  import { mapCountry } from '@/utils/mapping'

  export default {
    props: {
      invoice: {
        type: Object,
        default: null
      }
    },

    computed: {
      date() {
        return formatDate(this.invoice.date, 'MMMM do, yyyy')
      },

      hwmDate() {
        return formatDate(this.invoice.hwm.previous_date, 'MMMM yyyy')
      },

      hwmNewDate() {
        return formatDate(this.invoice.hwm.new_date, 'MMMM yyyy')
      },

      serviceDate() {
        return formatDate(this.invoice.period.date, 'MMMM yyyy')
      }
    },

    methods: {
      mapCountry,
      formatDate,
      formatDecimal
    }
  }
</script>

<style lang="sass" scoped>
  @import '@/sass/styles.vars.all.sass'

  .InvoiceInfo
    break-after: avoid-page
    padding: $spacer $spacer-5

  .InvoiceInfo__top-one
    margin-bottom: $spacer-4

  // LEFT

  .InvoiceInfo__top-one__left
    font-size: 1.25em

    time
      background: $primary
      color: white
      padding: .5em 1em
      margin-left: .5em
      font-weight: bold

  .InvoiceInfo__top-two__left
    h2
      text-transform: uppercase
      color: $primary
      font-size: 1em

    h3
      text-transform: uppercase
      color: $gray-800
      font-size: 1.5em

  // RIGHT

  .InvoiceInfo__top-one__right
    font-size: 1.2em

    strong
      margin-right: .5em

    span
      display: inline-block
      min-width: 8em

  .InvoiceInfo__top-two__left
    h3
      font-size: 16px

    h4
      font-size: 12px
      margin-top: -.25em

  .InvoiceInfo__top-two__right
    h2
      text-transform: uppercase
      color: $primary
      font-size: 1em

  // BOTH

  .InvoiceInfo__top-one__left,
  .InvoiceInfo__top-two__left
    text-align: left

  .InvoiceInfo__top-one__right,
  .InvoiceInfo__top-two__right
    text-align: right

</style>
