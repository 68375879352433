<template>
  <footer class="InvoiceFooter" v-if="invoice">
    <div class="InvoiceFooter__page">
      {{ page }}
    </div>

    <div class="InvoiceFooter__thanks">
      Thank you for entrusting us with your portfolio!
    </div>

    <div class="InvoiceFooter__links">
      <div>
        <i class="bi bi-globe" />
        {{ invoice.global_settings.invoice_website }}
      </div>

      <div>
        <i class="bi bi-envelope" />
        {{ invoice.global_settings.invoice_contact_email }}
      </div>
    </div>

    <div class="InvoiceFooter__legals">
      {{ invoice.global_settings.invoice_footer }}
    </div>
  </footer>
</template>

<script>
  export default {
    props: {
      invoice: {
        type: Object,
        default: null
      },

      page: {
        type: String,
        default: ''
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import '@/sass/styles.vars.all.sass'

  .InvoiceFooter
    break-after: avoid-page
    padding: $spacer-4 $spacer-5
    display: flex
    flex-direction: column
    gap: $spacer-1
    background: lighten($secondary, 45)
    color: black
    position: relative

  .InvoiceFooter__thanks
    font-size: 1.2em
    font-weight: bold
    color: $tertiary

  .InvoiceFooter__links
    display: flex
    gap: $spacer
    align-items: center

  .InvoiceFooter__legals
    font-size: .75em

  .InvoiceFooter__page
    position: absolute
    right: $spacer-5
    bottom: $spacer-4
</style>
