import api from '@/shared/api/token'
import { orderBy } from 'lodash'
import { padNumber } from '@/utils/number'

const ENDPOINT = '/invoices'

class InvoicesService {
  fetch() {
    return api.get(ENDPOINT).then((response) => {
      return response.data.data
    })
  }

  fetchReport() {
    return api.get(ENDPOINT)
      .then((response) => {
        return response.data.data
      })
      .then((response) => {
        const report = { total: 0, year: {} }
        const invoices = orderBy(response, 'date', 'desc')

        invoices.forEach((invoice) => {
          const date = new Date(invoice.date)
          const year = 'Y' + date.getFullYear()
          const month = 'M' + padNumber(date.getMonth() + 1, 2)
          const due = invoice.due.performance_fee + invoice.due.quaterly_fee

          if (!report.year[year]) {
            report.year[year] = { total: 0, month: {} }
          }

          if (!report.year[year].month) {
            report.year[year].month = {}
          }

          if (!report.year[year].month[month]) {
            report.year[year].month[month] = { total: 0, invoices: [] }
          }

          report.total += due
          report.year[year].total += due
          report.year[year].month[month].total += due
          report.year[year].month[month].invoices.push(invoice)
        })

        return report
      })
  }

  get(id) {
    return api.get(ENDPOINT + '/' + id)
      .then((response) => {
        return response.data.data
      })
  }

  sendAll() {
    return api.put(ENDPOINT + '/send-all').then((response) => {
      return response.data.data
    })
  }
}

export default new InvoicesService()
