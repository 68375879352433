<template>
  <div class="InvoicePage">
    <div class="InvoicePage__header">
      <InvoiceHeader :invoice="invoice" />
    </div>

    <div class="InvoicePage__header">
      <InvoiceInfo :invoice="invoice" />
    </div>

    <div class="InvoicePage__body">
      <InvoicePeriod :invoice="invoice" />
    </div>

    <div class="InvoicePage__footer">
      <InvoiceFooter :invoice="invoice" :page="page" />
    </div>
  </div>
</template>

<script>
  import InvoiceHeader from '@/components/InvoiceHeader.vue'
  import InvoicePeriod from '@/components/InvoicePeriod.vue'
  import InvoiceFooter from '@/components/InvoiceFooter.vue'
  import InvoiceInfo from '@/components/InvoiceInfo.vue'

  export default {
    components: {
      InvoicePeriod,
      InvoiceFooter,
      InvoiceHeader,
      InvoiceInfo
    },

    props: {
      invoice: {
        type: Object,
        default: null
      },
      page: {
        type: String,
        default: null
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import '@/sass/styles.vars.all.sass'

  .InvoicePage
    width: 210mm
    height: 296.8mm
    position: relative
    box-shadow: 0 20px 20px rgba(gray, .5)
    margin: 50px auto
    background: white
    font-size: .7em
    display: flex
    flex-direction: column

    @page
      size: A4
      margin: 0
      padding: 0

    @media print
      margin: 0
      padding: 0
      box-shadow: none

    .InvoicePage__header
      margin-bottom: 10px

    .InvoicePage__footer
      margin-top: 10px

    .InvoicePage__body
      flex: 1 1 auto

    .InvoicePage__payment
      margin-top: auto
</style>
