<template>
  <div class="ExchangeName">
    <div v-if="exchange">
      <img class="ExchangeName__logo" :src="logo" v-if="logo">
      <span class="ExchangeName__name">{{ name }}</span>
    </div>
    <div v-if="!exchange">
      No exchange
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex'

  export default {
    props: {
      exchange: {
        type: String,
        default: ''
      }
    },

    data() {
      return {
        name: '',
        logo: ''
      }
    },

    computed: {
      ...mapState('exchanges', [ 'exchanges' ])
    },

    watch: {
      exchange: {
        immediate: true,
        handler(value) {
          if (this.exchanges[value]) {
            this.name = this.exchanges[value].name
            this.logo = this.exchanges[value].logo
          }
        }
      },
      exchanges: {
        immediate: true,
        handler() {
          if (this.exchanges[this.exchange]) {
            this.name = this.exchanges[this.exchange].name
            this.logo = this.exchanges[this.exchange].logo
          }
        }
      }
    }
  }
</script>

<style lang="sass" scoped>
  .ExchangeName
    display: flex
    align-items: center

  .ExchangeName__logo
    width: 2em
    height: 2em
    object-fit: contain
    margin-top: -.25em
</style>
